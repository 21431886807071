import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import JotformEmbed from 'react-jotform-embed'
import styled from 'styled-components'

const StyledDivMain = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  background: #423E42;

  @media (min-width: 768px) {
    height: 250px;
  }

  @media (min-width: 1024px) {
    height: 330px;
  }

  @media (min-width: 1280px) {
    height: 420px;
  }

  @media (min-width: 1536px) {
    height: 480px;
  }

  @media (min-width: 1920px) {
    height: 600px;
  }

  .styled-static-image {
    object-fit: contain;
    opacity: 0.4;
    width: 100%;
    height: 100%;
  }
`

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

function PageCareerSectionNew() {
  return (
    <>
      <StyledDivMain>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          // This is a presentational image, so the alt should be an empty string
          alt="Career Page Hero Background"
          src={"../../images/backgrounds/new-tiarna-team.jpg"}
          formats={["auto", "webp", "avif"]}
          className="styled-static-image" />
      </StyledDivMain>

      <div className='w-full bg-site-red'>
        <div className="w-11/12 py-16 mx-auto">
          <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>Tiarna Careers</h2>
        </div>
      </div>

      <div className="w-full bg-site-blue">
        <div className='relative w-11/12 max-w-screen-lg py-12 md:py-16 lg:w-10/12 lg:py-20 mx-auto'>
          <div className='w-full mx-auto'>
            <p className='text-white text-xl mb-6'>Tiarna Real Estate Services, Inc. was established in the year 2000 by a close-knit group of seasoned industry professionals who possess a shared vision of unparalleled excellence and a superior standard of real estate services.</p>

            <p className='text-white text-xl mb-6'>We invite you to embark on a pathway to success and forge your ideal professional trajectory by becoming a valued member of our team which boasts a family atmosphere, a diverse workforce and career progression.</p>

            <p className='text-white text-xl mb-6'>We continuously seek out individuals who embody a positive, customer-oriented mindset, exhibit unwavering dependability, exemplify a robust work ethic, demonstrate efficiency, maintain unwavering commitment, and thrive in a collaborative setting.</p>

            <p className='text-white text-xl mb-6'>Tiarna places great value upon its employees, aspiring for individuals who are looking for a promising future and a harmonious work-life equilibrium, accompanied by exceptional leadership capabilities and dedication to fulfilling Tiarna’s objective and our clients’ goals.</p>

            <p className='text-white text-xl'>Join us and embark on a journey of professional growth and fulfillment as we collectively endeavor to redefine the standards of excellence in the real estate industry.</p>
          </div>
        </div>
      </div>

      <div className='w-full bg-site-red'>
        <div className="w-11/12 py-16 mx-auto">
          <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>We currently have the following positions available:</h2>
        </div>
      </div>

      <div className="w-full bg-gray-100">
        <div className='relative w-11/12 grid grid-cols-1 md:grid-cols-2 gap-6 py-12 md:py-16 lg:w-8/12 lg:py-20 mx-auto'>
          <div>
            <h3 className='text-3xl lg:text-4xl text-site-blue font-extrabold mb-6'>Portfolio Manager</h3>

            <StyledUnderline className='mb-6'></StyledUnderline>
          </div>

          <div>
            <h3 className='text-3xl lg:text-4xl text-site-blue font-extrabold mb-6'>Property Manager</h3>

            <StyledUnderline className='mb-6'></StyledUnderline>
          </div>

          <div>
            <h3 className='text-3xl lg:text-4xl text-site-blue font-extrabold mb-6'>Assistant Manager</h3>

            <StyledUnderline className='mb-6'></StyledUnderline>
          </div>

          <div>
            <h3 className='text-3xl lg:text-4xl text-site-blue font-extrabold mb-6'>Property Coordinator</h3>

            <StyledUnderline className='mb-6'></StyledUnderline>
          </div>

          <div>
            <h3 className='text-3xl lg:text-4xl text-site-blue font-extrabold mb-6'>Accountant</h3>

            <StyledUnderline className='mb-6'></StyledUnderline>
          </div>

          <div>
            <h3 className='text-3xl lg:text-4xl text-site-blue font-extrabold mb-6'>Intern</h3>

            <StyledUnderline className='mb-6'></StyledUnderline>
          </div>
        </div>
      </div>

      <div className='w-full bg-site-red'>
        <div className="w-11/12 py-16 mx-auto">
          <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>Submit Resume</h2>
        </div>
      </div>

      <div className="w-full bg-gray-100">
        <div className='relative w-11/12 max-w-screen-xl mx-auto'>
          <JotformEmbed src="https://form.jotform.com/231911651274453" />
        </div>
      </div>
    </>
  )
}

export default PageCareerSectionNew