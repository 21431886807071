import * as React from "react"

import LayoutPage from "../components/layout/layout-page"
import PageCareerSectionNew from "../components/sections/page-career-section-new"
import Seo from "../components/seo"

const CareerPage = () => (
  <LayoutPage>
    <Seo title="Career" />

    <PageCareerSectionNew />
  </LayoutPage>
)

export default CareerPage
